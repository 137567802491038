@font-face {
    font-family: IBMPlexMono;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
}

@font-face {
    font-family: IBMPlexMonoLight;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
}

@font-face {
    font-family: IBMPlexMonoExtraLight;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
}

:root {
    --color-bg: #f4f5f7;
    --color-primary: #4e7dd9;
    --color-hover: #3b5bb5;
    --color-danger: #ff4500;
}

.bgGray100 {
    background-color: var(--color-bg);
    min-height: 100vh;
}

.header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    align-items: center;
    background-color: rgba(244, 227, 219, 0);
    border: 0.5px solid rgba(181, 181, 181, 0.734);
    margin: 1em;
    margin-bottom: 0px;
    border-radius: 20px 20px 0 0;
    height: 3.125rem;
}

.border {
    border-left: 0.5px solid rgba(181, 181, 181, 0.734);
    border-right: 0.5px solid rgba(181, 181, 181, 0.734);
    margin: 0 1em;
}

.menuIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 100%;
    border-right: 0.5px solid rgba(181, 181, 181, 0.734);
    cursor: pointer;
}

.hamburger {
    width: 20px;
    height: 2px;
    background-color: #000;
    position: relative;
}

.hamburger::before,
.hamburger::after {
    content: '';
    width: 20px;
    height: 2px;
    background-color: #000;
    position: absolute;
    left: 0;
    transition: all 0.3s ease;
}

.hamburger::before {
    top: -6px;
}

.hamburger::after {
    bottom: -6px;
}

.navOptions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
}

.option {
    font-family: IBMPlexMonoLight;
    text-transform: uppercase;
    font-size: 12px;
    color: #141414;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.option:hover {
    background-color: #fff;
    color: #000;
}

.selected {
    background-color: #fff;
    color: #000;
}

.signIn {
    justify-self: end;
    height: 100%;
    border-top-right-radius: 20px;
    width: 8rem;
    background-color: #fff;
    color: #141414;
    cursor: pointer;
    font-family: IBMPlexMono;
    transition: background-color 0.3s ease, color 0.3s ease;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 16px 100%, 0 calc(100% - 16px));
    display: flex;
    justify-content: center;
    padding: 1em; /* Increased padding for touch targets */
    align-items: center;
}

.signIn:hover {
    background-color: #f0f0f0;
    color: #000;
}

.optionsStyle {
    width: 100%;
    max-width: 315px;
    height: 59px;
    flex-shrink: 0;
    border-radius: 33px;
    background: #EBEFF1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 2rem 0 20px;
}

button {
    border: 1px solid #FF4B2B;
    background-color: #FF4900;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 0.6s ease-in-out;
}

.modeButton {
    transition: background-color 0.3s ease, color 0.3s ease;
}

.selectedButton, .unselectedButton {
    width: 45%;
    height: 45px;
    flex-shrink: 0;
    border-radius: 33px;
    padding: 15px 25px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 20px 40px 0px rgba(55, 62, 125, 0.10);
}

.selectedButton {
    background: rgba(255, 255, 255, 0.80);
    color: #141414;
}

.unselectedButton {
    background: transparent;
    color: #000;
    opacity: 0.5;
}

.iconButton {
    background-color: #fff;
    padding: 16px;
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.inputField {
    background: none;
    border: none;
    border-bottom: 1.5px solid #d7dedd;
    color: #1f1f1fcc;
    font-family: IBMPlexMonoLight;
    margin: 5px 0;
    outline: none;
    padding: 20px 10px 10px 20px;
    width: min-content;
}

.inputField:focus {
    border-color: var(--color-primary);
}

.searchButton {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--color-primary);
    color: #fff;
    font-weight: bold;
    transition: all 0.3s ease;
    cursor: pointer;
}

.searchButton:hover {
    background-color: var(--color-hover);
}

.borderOrange500 {
    border-color: var(--color-danger);
}

.gridMapAndWorxes {
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    gap: 0.5em;
}

.titleWorx {
    font-family: IBMPlexMono;
    font-size: 20px;
    color: #141414;
}

.imgWorx {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.gridCard {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin-bottom: 1em;
    border-bottom: 0.5px solid rgba(181, 181, 181, 0.734);
    padding-bottom: 1em;
    cursor: pointer;
}

.worxInfo {
    display: grid;
    padding-left: 1em;
}

.gridPriceAndRating {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    align-items: end;
}

.rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: IBMPlexMonoExtraLight;
    font-size: 14px;
    gap: 5px;
}

.address, .aminities {
    font-family: IBMPlexMonoExtraLight;
    font-size: 14px;
}

.name {
    font-family: IBMPlexMonoExtraLight;
    font-size: 16px;
    font-weight: bolder;
    color: #141414;
}

.price {
    justify-self: end;
    font-family: IBMPlexMonoExtraLight;
    font-size: 14px;
}

.borderDiv {
    border-bottom: 0.5px solid rgba(181, 181, 181, 0.734);
    margin: 2% 0 2em;
}

.stickyMap {
    position: sticky;
    top: 0;
    z-index: 10;
}

.smallBurgerMenu {
    width: 30px;
    height: auto; 
}

.offersList {
    padding: 1rem;
    max-width: 1200px;
    margin: 0 auto;
}

.hotelCategory {
    margin-bottom: 2rem;
    border: 1px solid rgba(181, 181, 181, 0.734);
    border-radius: 0.5rem;
    overflow: hidden;
}

.hotelTrigger {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    background: white;
    border: none;
    cursor: pointer;
    border-radius: 0px !important;
    color: black !important;
}

.hotelHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: 1rem;
}

.hotelRating {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-family: IBMPlexMonoLight;
}

.offerCategory {
    margin: 1rem;
    border-bottom: 1px solid rgba(181, 181, 181, 0.734);
    padding-bottom: 1rem;
}

.categoryTrigger {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: none;
    border: none;
    cursor: pointer;
}

.categoryTrigger:hover h2 {
    opacity: 0.8;
}

.offerWorx {
    font-family: IBMPlexMonoExtraLight, serif;
    font-size: 14px;
    margin-left: 1.5rem;
    margin-top: 1rem;
}


.offerAddress {
    font-family: IBMPlexMonoExtraLight, serif;
    font-size: 14px;
    display: flex;
}



.offersGrid {
    display: grid;

    gap: 2rem;
    padding: 1rem;
}

.offerCard {
    background-color: #fff;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 100%;
}

.offerCardInner {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.offerImageContainer {
    position: relative;
    width: 100%;
}

.offerImage {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.discountBadge {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background-color: #22c55e;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-family: IBMPlexMonoLight;
    font-weight: bold;
}

.offerContent {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.offerDetails {
    flex: 1;
}

.offerPricing {
    margin: 1rem 0;
}

.priceContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.originalPrice {
    font-family: IBMPlexMonoLight;
    text-decoration: line-through;
    color: #6b7280;
}

.offerAction {
    margin-top: auto;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
}

@media (min-width: 1024px) {
    .offersGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 1280px) {
    .offersGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 570px) {
    .gridMapAndWorxes {
        grid-template-columns: 1fr;
    }

    .stickyMap {
        order: 1;
    }

    .worxList {
        order: 2;
    }

    .gridCard {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align the content */
        text-align: center;
        margin: 0 0.3rem;
    }

    .imgWorx {
        height: auto;
        margin-bottom: 10px;
        width: 100%; /* Ensure image takes full width */
    }

    .worxInfo {
        padding: 0;
        text-align: left;
        width: 100%; /* Ensure info takes full width */
    }
}

