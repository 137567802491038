@font-face {
    font-family: IBMPlexMono;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
}

@font-face {
    font-family: IBMPlexMonoLight;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
}

@font-face {
    font-family: IBMPlexMonoExtraLight;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
}

.bookingCard {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    font-family: IBMPlexMono;
}

.bookingCardDate {
    font-weight: bold;
    margin-bottom: 5px;
}

.bookingCardDetails {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 15px;
}

.bookingCardImage img {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.bookingCardInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bookingCardHotel {
    font-size: 1.2em;
    margin-bottom: 5px;
    font-family: IBMPlexMono;
}

.bookingCardAddress {
    color: #666;
    margin-bottom: 5px;
    font-family: IBMPlexMonoLight;
}

.bookingCardTimeCost {
    display: flex;
    justify-content: space-between;
    font-family: IBMPlexMonoExtraLight;
}

.spans {
    font-size: 14px;
}
