@font-face {
    font-family: IBMPlexMono;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
}

@font-face {
    font-family: IBMPlexMonoLight;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
}

@font-face {
    font-family: IBMPlexMonoExtraLight;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
}

.feedbackFormContainer {
    background-color: #f4f5f7;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    font-family: IBMPlexMonoLight;
    padding: 5%;
}

.submitFeedbackText {
    padding-bottom: 25px;
}

.formGroup {
    padding-top: 15px;
    padding-bottom: 25px;
    width: 75%;
    padding-inline: 10%;
    margin: 0 auto;
}

.formGroup label {
    display: block;
    margin-bottom: 5px;
    font-family: IBMPlexMono;
    color: #141414;
}

.textarea,
.input {
    width: 100%;
    height: 25vh; /* Increased height for the textarea */
    padding: 8px;
    box-sizing: border-box;
    background: none;
    border: none;
    border: 1.5px solid #d7dedd;
    color: #1f1f1fcc;
    margin: 5px 0;
    outline: none;
    font-family: IBMPlexMonoLight;
}


.textarea:focus,
.input:focus {
    border-color: #4e7dd9;
}

.starRating {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
}

.star {
    font-size: 2rem;
    cursor: pointer;
    color: #ccc;
    transition: color 0.3s ease;
}

.starFilled {
    color: #ffdd00;
}

.button {
    padding: 16px;
    border-radius: 8px;
    background-color: #4e7dd9;
    color: #fff;
    font-family: IBMPlexMono;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
    width: 100%;
    text-align: center;
    width: 35%;
}

.button:hover {
    background-color: #3b5bb5;
}

.buttonDisabled {
    background-color: #999;
}

.successMessage {
    color: green;
    margin-top: 10px;
    font-family: IBMPlexMonoLight;
}

.errorMessage {
    color: red;
    margin-top: 10px;
    font-family: IBMPlexMonoLight;
}
