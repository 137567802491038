.hoverEffectButton {
    font-family: IBMPlexMonoLight;
    text-transform: uppercase;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    margin:auto;
    margin-top: 1em;
    display:block;
  }
  
  .hoverEffectButton:hover {
    background-color: #fff;
    color: #000;
  }
  
  @media (max-width: 570px) {
    .hoverEffectButton {
        font-size: 10px; 
        padding: 8px 12px;
        width: 100px;
        height: auto; 
    }
}