@font-face {
    font-family: IBMPlexMono;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
}

@font-face {
    font-family: IBMPlexMonoLight;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
}

@font-face {
    font-family: IBMPlexMonoExtraLight;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
}

.appContainer {
    min-height: 100vh;
    background-color: #f4f5f7;
    display: flex;
    flex-direction: column;
}

.flex {
    display: flex;
}

.flexCol {
    flex-direction: column;
}

.flexRow {
    flex-direction: row;
    margin-top: 1em;
}

.flexGrow {
    flex-grow: 1;
    margin: 0 1em;
    border-left: 0.5px solid rgba(181, 181, 181, 0.734);
    border-right: 0.5px solid rgba(181, 181, 181, 0.734);
    border-bottom: 0.5px solid rgba(181, 181, 181, 0.734);
}

.flexInformation {
    flex-grow: 1;
    margin: 0 1em;
}

.overflowAuto {
    overflow: auto;
}

.bgGray100 {
    background-color: #f3f4f5;
}

/* Booking summary styles */
.bookingImageContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;
}

.bookingImage {
    width: 100%;
    height: 25em;
    object-fit: cover;
}

.bookingDetails {
    margin-top: 1rem;
}

.bookingDetailsTitle {
    font-size: 1.25rem;
    font-weight: 600;
    font-family: IBMPlexMono;
}

.bookingDetailsText {
    color: #4b5563;
    font-family: IBMPlexMonoLight;
    line-height: 1;
}

.timeTable {
    margin: 0.5em 0px;
    font-family: IBMPlexMonoLight;
    font-size: small;
}

.fontSemibold {
    font-family: IBMPlexMonoLight;
    font-size: small;
}

.bookingTimes {
    border-top: 1px solid #e0e0e0;
    padding-top: 0.5rem;
}

.bookingTimesTitle {
    font-size: 1.125rem;
    font-weight: 600;
    font-family: IBMPlexMono;
}

.bookingTimesText {
    margin-bottom: 0.5rem;
}

.priceDetails {
    border-top: 1px solid #e0e0e0;
    padding: 0.5rem 0;
    margin: 0.5rem 0;
}

.priceDetailsTitle {
    font-size: 1.125rem;
    font-weight: 600;
    font-family: IBMPlexMono;
}

.priceDetailsText {
    display: flex;
    justify-content: space-between;
    font-family: IBMPlexMonoLight;
}

.priceDetailsTotal {
    font-weight: 600;
}

/* Payment section styles */

.flexPayment {
    flex-grow: 1;
    margin: 0 1em;
}

.paymentSection {
    padding: 1rem;
    border: 1px solid #e0e0e0;
    border-radius: 1rem;
    background-color: #f3f4f5;
}

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Location section styles */
.locationSection {
    margin-top: 1rem;
    padding-top: 0.5rem;
    border-top: 1px solid #e0e0e0;
}

.locationTitle {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    margin-left: 1rem;
    font-family: IBMPlexMono;
}

.locationDetails {
    margin-bottom: 1rem;
    margin-left: 1rem;
    font-family: IBMPlexMonoLight;
    font-size: small;
}

.locationDetailsText {
    margin: 0.2rem;
}

.mapContainer {
    height: 16rem;
    width: 100%;
}

@media (max-width: 768px) {
    .flexRow {
        flex-direction: column;
        /* Stack items on smaller screens */
    }

    .flexInformation {
        margin: 0;
        /* Remove margins on small screens */
    }

    .priceDetails {
        padding: 1rem;
        /* Increased padding for touch targets */
    }

    .bookingImage {
        height: 120px;
    }

    .locationTitle {
        font-size: 1.125rem;
    }

    .bookingDetails {
        margin-left: 0.35rem;
    }

    .bookingDetailsTitle {
        font-size: 1.125rem;
        /* Smaller font size for titles */
        margin-bottom: 1.5rem; /* Add margin-bottom for mobile */
    }

    .bookingTimes{
        margin-left: 0.35rem;
    }

    .bookingTimesTitle {
        font-size: 1rem;
        /* Smaller font size for booking times */
    }

    .timeTable {
        font-size: small;
        /* Ensure readability */
    }

    .priceDetailsText {
        font-size: small;
        /* Ensure readability */
    }

    .locationDetailsText {
        margin: 0.2rem 0 0.2rem 0.5rem; /* Smaller margin-left for mobile */
    }
}