@font-face {
    font-family: IBMPlexMonoLight;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
  }

  @font-face {
    font-family: IBMPlexMono;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Regular.ttf");
  }
  
  .hoverEffectButton {
    font-family: IBMPlexMono;
    text-transform: uppercase;
    font-style: bold;
    font-size: 12px;
    color: #fff;
    background-color: #ff4900;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    padding: 10px 20px;
    margin: 0 auto;
    border: none;
    border-top-left-radius: 1px;
    border-bottom-right-radius: 0px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 16px 100%, 0 calc(100% - 16px));
    display: inline-block;
    height: fit-content;
  }
  
  .hoverEffectButton:hover {
    background-color: #fff;
    color: #ff4900;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-decoration: underline;
  }
  
  .hoverEffectButton.selected {
    background-color: #fff;
    color: #ff4900;
  }
  