.hoverEffectDiv {
    font-family: IBMPlexMonoLight;
    text-transform: uppercase;
    font-size: 12px;
    color: #141414;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    width: fit-content;
    padding: 8px 16px; /* Add padding to make it look like a button */
    background-color: #f9f9f9; /* Light background color */
    border: 1px solid #ccc; /* Light border */
    border-radius: 20px; /* Rounded corners */
}

.hoverEffectDiv:hover {
    background-color: #e0e0e0; /* Slightly darker background on hover */
    color: #000;
}

.hoverEffectDiv.selected {
    background-color: #ff4900; /* Selected state background */
    color: white; /* White text when selected */
    border-color: #ff4900; /* Change border color to match selected state */
}

@media (max-width: 570px) {
    .hoverEffectDiv {
        font-size: 10px; 
    }
}
