.amenities-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    /* Increased min size */
    column-gap: 0.6em;
    row-gap: 1em; /* Vertical space between rows */
    width: 100%
}

.amenity {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s;
}

.amenity.selected {
    background-color: #e0f7fa;
    border-color: #26c6da;
}

.amenity-icon {
    width: 25px;
    height: 25px;
    object-fit: contain;
    margin: 0.5rem auto;
}

.amenity-name {
    font-size: 0.9rem;
    font-weight: normal;
    margin: 0;
    color: #333;
}