  
  .loginBody {
    background: linear-gradient(270deg, #ffffff, #cbcaca, #b4b4b4);
    background-size: 600% 600%;
    animation: bodyWave 12s ease infinite;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Montserrat', sans-serif;
    height: 100vh;
    margin: 0;
  }
  
  @keyframes bodyWave {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  h1 {
    font-weight: bold;
    margin: 0;
  }
  
  h2 {
    text-align: center;
  }
  
  p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
  }
  
  span {
    font-size: 12px;
  }
  
  a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
  }
  
  button {
    border-radius: 20px;
    border: 1px solid #FF4B2B;
    background-color: #FF4900;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 0.6s ease-in-out;
  }

  .button87 {
    padding: 13px 30px !important;
    width: 60%;
    text-align: center !important;
    text-transform: uppercase !important;
    transition: background-position 0.5s ease, transform 0.5s ease !important;
    color: white !important;
    border-radius: 20px !important;
    border: 0px !important;
    box-shadow: 0px 0px 14px -7px #FF4B2B !important;
    background-image: linear-gradient(45deg, #FF4B2B 15%, #ffded869 42%, #FF4B2B 100%) !important;
    cursor: pointer !important;
    user-select: none !important;
    -webkit-user-select: none !important;
    touch-action: manipulation !important;
    background-size: 200% auto !important;
    animation: moveGradient 4s linear infinite alternate;
  }

  @keyframes moveGradient {
      0% {
          background-position: 0% center;
      }
      100% {
          background-position: 100% center;
      }
  }

  .button87:hover {
      background-position: 100% center !important;
      color: #fff !important;
      text-decoration: none !important;
      transform: scale(0.95); 
      
  }

  .button87:active {
      transform: scale(0.95) !important;
  }


.button60 {
  width: 60%;
  align-items: center;
  appearance: none;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 20px;
  box-shadow: none;
  box-sizing: border-box;
  color: #363636;
  cursor: pointer;
  display: inline-flex;
  font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  justify-content: center;
  padding: 12px 30px !important;
  position: relative;
  text-align: center !important;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  margin-top: 25px;
}

.logo_cursor {
  cursor: pointer;
}

.button60:active {
  border-color: #4a4a4a;
  outline: 0;
}

.button60:focus {
  border-color: #485fc7;
  outline: 0;
}

.button60:hover {
  border-color: #b5b5b5;
}

.button60:focus:not(:active) {
  box-shadow: rgba(72, 95, 199, .25) 0 0 0 .125em;
}
  
  button:active {
    transform: scale(0.95);
  }
  
  button:focus {
    outline: none;
  }
  
  button.ghost {
    background-color: transparent;
    border-color: #FFFFFF;
    margin-top: 1rem;
  }

  .buttonOptions {
    padding: 13px 30px !important;
    width: 60%;
    text-align: center !important;
    text-transform: uppercase !important;
    transition: background-position 0.5s ease, transform 0.5s ease !important;
    color: white !important;
    border-radius: 20px !important;
    border: 0px !important;
    box-shadow: 0px 0px 14px -7px #FF4B2B !important;
    cursor: pointer !important;
    user-select: none !important;
    -webkit-user-select: none !important;
    touch-action: manipulation !important;
    background-size: 200% auto !important;
  }
  
  form {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
  }
  
  input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
  }
  
  .container {
    background-color: #fff;
    border-radius: 10px;
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
        0 10px 10px rgba(0,0,0,0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
  }
  
  .formContainer {
    position: absolute;
    top: 0;
    height: 100%;
    transition: transform 0.6s ease-in-out;
  }
  
  .signInContainer {
    left: 0;
    width: 50%;
    z-index: 2;
  }
  
  .container.rightPanelActive .signInContainer {
    transform: translateX(100%);
  }
  
  .signUpContainer {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
    transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  }
  
  .container.rightPanelActive .signUpContainer {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s; 
  }
  
  
  .buttonOptions:hover {
    transform: scale(0.95); 
  }
  
  @keyframes show {
    0%, 49.99% {
      opacity: 0;
      z-index: 1;
    }
    
    50%, 100% {
      opacity: 1;
      z-index: 5;
    }
  }
  
  .overlayContainer {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }
  
  .container.rightPanelActive .overlayContainer{
    transform: translateX(-100%);
  }
  
  @keyframes gradientWave {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .overlay {
    background: linear-gradient(270deg, #FFC2A9, #FF834F, #FFBCA1);
    background-size: 600% 600%;
    animation: gradientWave 10s ease infinite;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }
  
  .container.rightPanelActive .overlay {
      transform: translateX(50%);
  }
  
  .overlayPanel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }
  
  .overlayLeft {
    transform: translateX(-20%);
  }
  
  .container.rightPanelActive .overlayLeft {
    transform: translateX(0);
  }
  
  .overlayRight {
    right: 0;
    transform: translateX(0);
  }
  
  .container.rightPanelActive .overlayRight {
    transform: translateX(20%);
  }
  
  .socialContainer {
    margin: 20px 0;
  }
  
  .socialContainer a {
    border: 1px solid #DDDDDD;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
  }
  
  footer {
      background-color: #222;
      color: #fff;
      font-size: 14px;
      bottom: 0;
      position: fixed;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 999;
  }
  
  footer p {
      margin: 10px 0;
  }
  
  footer i {
      color: red;
  }
  
  footer a {
      color: #3c97bf;
      text-decoration: none;
  }


  @media (max-width: 768px) {
    button.ghost {
      width: 80%;
      margin-top: 1rem;
      display: inline-flex;
      text-align: center;
      justify-content: center;
    }
  }
  
