@font-face {
    font-family: IBMPlexMono;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
}

@font-face {
    font-family: IBMPlexMonoLight;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
}

@font-face {
    font-family: IBMPlexMonoExtraLight;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
}

.bgGray100 {
    background-color: #f4f5f7;
    min-height: 100vh;
}

.container {
    margin-left: 1em;
    margin-right: 1em;
    display: flex;
    flex-direction: column;
    border-left: 0.5px solid rgba(181, 181, 181, 0.734);
    border-right: 0.5px solid rgba(181, 181, 181, 0.734);
    border-bottom:  0.5px solid rgba(181, 181, 181, 0.734);
    padding-bottom: 3em;
}

.userHeader {
    text-align: center;
    padding: 20px;
}

.userInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profilePhoto {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
    position: relative;
}

.profilePhotoPlaceholder {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: linear-gradient(to bottom, #DEEFFF, #BCDFFF);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    position: relative;
}

.userDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profileName {
    font-weight: bold;
    color: #191C32;
    text-align: center;
    font-family: IBMPlexMono;
}

.profileUpgrade {
    color: #3B82F6;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
}

.profileUpgradeIcon {
    margin-left: 5px;
}

.profileForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2em;
}

.formGroup {
    margin-bottom: 0.3em;
}

.formLabel {
    display: block;
    color: #6B7280;
    margin-bottom: 5px;
}

.formInput {
    background: none;
    border: none;
    border-bottom: 1.5px solid #d7dedd;
    color: #1f1f1fcc;
    font-family: IBMPlexMonoLight;
    outline: none;
    padding: 20px 10px 10px 20px;
}

.saveButton {
    width: 50%;
    padding: 10px;
    background-color: #3B82F6;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}

.logoutButton {
    width: 50%;
    padding: 10px;
    background-color: #EF4444;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.profilePhotoContainer {
    position: relative;
    display: inline-block;
}

.editPhotoIcon {
    position: absolute;
    bottom: 0px; 
    right: 0px; 
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

@media (min-width: 768px) {
    .profile-container {
        max-width: 800px;
    }
}

@media (min-width: 1024px) {
    .profile-container {
        max-width: 1000px;
    }
}
