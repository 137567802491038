@font-face {
    font-family: IBMPlexMono;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
}

@font-face {
    font-family: IBMPlexMonoLight;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
}

@font-face {
    font-family: IBMPlexMonoExtraLight;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
}

.userName {
    justify-self: end;
    height: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 20px;
    width: max-content;
    height: 100%;
    cursor: pointer;
    font-family: IBMPlexMono;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
}

.path {
    border-top-right-radius: 20px;
    transition: background-color 0.3s ease, color 0.3s ease;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 16px 100%, 0 calc(100% - 16px));
    background-color: #fff;
    color: #141414;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: fit-content;
    padding-inline: 1em;
    display: flex;
}
.profilePhoto {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
    margin-right: 1em;
  }
  

.path:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #e2e2e2;
}

.dropdown {
    position: relative;
    background-color: transparent;
}

.dropdown_content {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #1e1e1e;
    color: white;
    min-width: 160px;
    max-height: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    border-radius: 8px;
    margin: 0px !important;
    overflow-y: auto;
}

.dropdown_content a {
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    color: white;
    transition: background-color 0.3s;
    margin: 0.2rem 0px !important;
}

.dropdown_content a:hover {
    background-color: #575757;
}

.show {
    display: block !important;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #1e1e1e;
    color: white;
}

.navOptions {
    display: flex;
    gap: 20px;
}

.signIn {
    justify-self: end;
    height: 100%;
    border-top-right-radius: 20px;
    width: 9rem;
    background-color: #fff;
    color: #141414;
    cursor: pointer;
    font-family: IBMPlexMono;
    transition: background-color 0.3s ease, color 0.3s ease;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 16px 100%, 0 calc(100% - 16px));
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%, 16px 100%, 0 calc(100% - 16px));
    display: flex;
    justify-content: center;
    padding-inline: 1em;
    align-items: center;
    font-size: 12px;
}

.smallProfileIconContainer {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    background: linear-gradient(to bottom, #DEEFFF, #BCDFFF);
    border-radius: 50%;
    display: flex;
    align-items: center; 
    justify-content: center; 
    margin: 0 auto; 
    margin-right: 1em;
}

.smallProfileIcon {
    width: 100%;
    height: 100%;
}


.menuIcon {
    cursor: pointer;
}

.hamburger {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
}

@media screen and (max-width: 570px) {
    .path {
        width: 5px;
    }
    
}
