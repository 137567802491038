@font-face {
  font-family: IBMPlexMono;
  src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
}

@font-face {
  font-family: IBMPlexMonoLight;
  src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
}

@font-face {
  font-family: IBMPlexMonoExtraLight;
  src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0);
  }
}

.menuBar {
  position: fixed;
  left: 1.5em;
  top: 1.5em;
  width: 40%;
  height: 45%;
  background-color: #0e0e0e;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-top: 2px solid #E5E7EB;
  border-radius: 10px;
  z-index: 50;
  animation: slideInFromLeft 0.5s ease-out forwards;
}

.menuContent {
  display: grid;
  grid-template-columns: auto 1fr auto;
  width: 100%;
  grid-gap: 10px;
  margin-bottom: 3em;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  padding: 8px;
}

.menuOptions {
  display: flex;
  flex-direction: column;
  gap: 5px;
  word-break: break-word; /* Ensure words break appropriately */
}

.discover {
  font-family: IBMPlexMono;
  font-size: 7px;
  color: #ffffff;
  text-transform: uppercase;
}

.connect {
  font-family: IBMPlexMono;
  font-size: 7px;
  color: #ffffff;
  align-self: flex-start;
  text-transform: uppercase;
  margin-top: 2px;
}

.socials {
  font-family: IBMPlexMono;
  font-size: 10px;
  color: #ffffff;
  margin: 0;
  text-transform: uppercase;
}

.socialsGrid {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 5px;
}

.cross {
  width: 30px;
  height: auto;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .menuBar {
    width: 70%;
    height: auto;
  }

  .menuOptions {
    word-break: break-word; /* Ensure words break appropriately */
  }
}

@media (max-width: 600px) {
  .menuBar {
    width: 80%;
    height: auto;
  }

  .menuOptions {
    word-break: break-word; /* Ensure words break appropriately */
  }
}

@media (max-width: 480px) {
  .menuBar {
    width: 90%;
    height: auto;
  }

  .menuOptions {
    word-break: break-word; /* Ensure words break appropriately */
  }
}
