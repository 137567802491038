.welcomingPage {
  --primary-color: #FF9400;
  --secundary-color: rgba(6, 210, 246, 0.147);

  height: 100vh;
  display: grid;
  place-items: center;
  background-color: black;
  background-image: url(../assets/externalSites/backgroundWelcome.jpg);
  background-size: cover;
  margin: 0;
  overflow-y: auto;
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
p {
  margin: 0;
}

.card {
  width: 80%;
  position: relative;
  background-color: rgba(16, 16, 16, 0.92);
  border: 1px solid rgb(255 255 255 / 5%);
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card:after {
  content: "";
  height: 70px;
  width: 1px;
  position: absolute;
  left: -1px;
  top: 65%;
  transition: top, opacity;
  transition-duration: 600ms;
  transition-timing-function: ease;
  background: linear-gradient(transparent,
      var(--primary-color),
      transparent);
  opacity: 0;
}

.card:after {
  top: 65%;
  opacity: 0;
}

.card:hover:after {
  top: 25%;
  opacity: 1;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-image: radial-gradient(rgba(6, 210, 246, 0.147) 1px,
      transparent 1px);
  background-position: 50% 50%;
  background-size: 1.1rem 1.1rem;
  padding: 2rem;
  border-radius: 1.25rem;
  overflow: hidden;
}

.cardContent> :is(h1, h3, p) {
  text-align: center;
}

.cardContent>h1 {
  color: rgb(250 249 246);
  font-size: 2.6rem;
}

.cardContent>h3 {
  color: var(--primary-color);
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
}

.cardContent>p {
  color: rgb(255 255 255 / 75%);
  line-height: 1.5rem;
  font-size: 1.15rem;
}

.cardLinks {
  display: flex;
  flex-direction: row;
  /* Default to row layout */
  gap: 0.5rem;
  margin-top: 1rem;
  align-items: center;
}

.cardLinkImage {
  width: 65%;
  /* Use percentage to make it relative to the parent container */
  max-width: 250px;
  /* Optionally set a max width to prevent the image from becoming too large */
  min-width: 100px;
  /* Optionally set a min width to prevent it from becoming too small */
  height: auto;
  /* Maintain aspect ratio */
  margin: 0 auto;
}

.logo {
  width: 200px;
  margin-bottom: 1rem;
}

@media(max-width: 700px) {
  .card {
    width: calc(100% - 2rem);
    margin: 0rem 1rem;
    padding: 0.75rem;
    border-radius: 1rem;
  }
}

@media(max-width: 600px) {
  .cardContent {
    padding: 3rem;
  }

  .cardContent>h1 {
    font-size: 2.2rem;
  }
}


.links {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 10;
  padding: 1rem;
}

.metaLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.metaLink> :is(span, a) {
  font-family: "Rubik", sans-serif;
  font-size: 1.25rem;
  color: white;
}

.metaLink>.label {
  text-align: right;
}

.metaLink>a {
  text-decoration: none;
  outline: none;
}

.metaLink>a.source {
  color: rgb(94, 106, 210);
}

.metaLink>a:is(:hover, :focus)>i {
  color: white;
}

.metaLink>a.youtube {
  color: rgb(239, 83, 80);
}

.metaLink>a.youtube>i {
  padding-top: 0.2rem;
}

.metaLink>a>i {
  height: 1rem;
  line-height: 1rem;
}

.socialMediaLinks {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.socialMediaIcon {
  width: 40px;
  height: 40px;
  transition: transform 0.3s ease;
}

.socialMediaIcon:hover {
  transform: scale(1.1);
}

.socialMediaLinks a {
  color: #ffffff;
  /* Change this to a lighter color */
}

.socialMediaLinks a:hover {
  color: #dddddd;
  /* Lighter color on hover */
}

@media (max-width: 768px) {
  .socialMediaLinks {
    gap: 15px;
  }

  .socialMediaIcon {
    width: 35px;
    height: 35px;
  }
}


/* Media query for phone screens */
@media (max-width: 600px) {
  .cardLinks {
    flex-direction: column;
    /* Change to column layout on small screens */
    align-items: stretch;
    /* Ensure links take full width */
  }

  .cardLinkImage {
    width: 100%;
    /* Make the images responsive to fit the container */
    max-width: 300px;
    /* Optional: Set a maximum width to prevent the images from becoming too large */
    margin: 0 auto;
    /* Center the images */
  }
}

@media (max-width: 600px) {
  .cardContent>p {
    text-align: justify;
    /* Justify text for paragraphs on small screens */
  }
}