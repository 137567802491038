@font-face {
    font-family: IBMPlexMono;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
}

@font-face {
    font-family: IBMPlexMonoLight;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
}

@font-face {
    font-family: IBMPlexMonoExtraLight;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
}

.bgGray100 {
    background-color: #f4f5f7;
    min-height: 100vh;
}

.container {
    margin: 0 1em;
    border: 0.5px solid rgba(181, 181, 181, 0.734);
}

.imageContainer {
    position: relative;
    height: 400px;
    width: 100%;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.priceBadge {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: black;
    color: white;
    padding: 5px 10px;
    font-size: 0.875rem;
    border-radius: 5px;
}

.columns {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Responsive columns */
    gap: 20px;
}

.leftColumn {
    padding: 20px;
}

.rightColumn {
    background-color: #f7f7f7;
    padding: 20px;
    border-left: 1px solid #e0e0e0;
}

.header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: IBMPlexMono;
}

.subHeader {
    font-size: 1.5rem;
    font-weight: semibold;
    margin-bottom: 5px;
    font-family: IBMPlexMono;
}

.address {
    color: #666;
    margin-bottom: 15px;
    font-family: IBMPlexMonoLight;
}

.availability {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    color: #333;
    font-family: IBMPlexMonoLight;
}

.tos {
    font-family: IBMPlexMonoLight;
    color: #666;
}

.availabilityDetails {
    display: none;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
}

.availabilityDetails.expanded {
    display: flex;
}

.priceTitle {
    font-family: IBMPlexMono;
    font-size: medium;
    margin-bottom: 1em;
    margin-top: 1em;
}

.priceList {
    margin-bottom: 20px;
}

.priceCard {
    background-color: white;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    text-align: center;
    font-family: IBMPlexMono;
    cursor: pointer;
}

.priceValue {
    font-weight: bold;
    margin-top: 5px;
}

.bestValueBadge {
    background-color: #d1fae5;
    color: #065f46;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 0.75rem;
    margin-top: 5px;
    display: inline-block;
}

.amenities {
    margin-bottom: 0.2em;
    font-family: IBMPlexMonoLight;
}

.amenitiesP {
    font-family: IBMPlexMonoLight;
}

.aminitiesTitle {
    font-family: IBMPlexMono;
    font-size: medium;
}

.amenity {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 5px;
}

.amenityIcon {
    width: 24px;
    height: 24px;
}

.worxDescription {
    margin-top: 1rem;
    font-family: IBMPlexMonoLight;
}

.mapContainer {
    margin-bottom: 35px;
    border-radius: 15px;
    overflow: hidden;
    height: 300px;
}

.bookingSection {
    background-color: white;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.bookingSectionTitle {
    font-family: IBMPlexMono;
    font-weight: bold;
    font-size: large;
}

.datePicker {
    width: 100%;
    padding: 8px;
}

.bookingSectionCalendar {
    display: flex;
    align-items: center;
    background-color: #f3f4f5;
    padding: 0.5rem;
    margin-bottom: 1rem;
}

.timeSection {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 1rem; /* Gap between items */
    background-color: #f7fafc; 
    padding: 1rem; 
    border-radius: 0.25rem; 
    margin-bottom: 1rem; 
}

.calendar {
    font-family: IBMPlexMono;
}

.hours {
    display: block; 
    color: #4a5568; 
    margin-bottom: 0.25rem; 
    font-family: IBMPlexMonoLight;
    font-size: small;
}

.timeSelect {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.1em;
}

.hoursSelector {
    display: flex;
    align-items: center;
    gap: 10px;
}

.incrementButton, .decrementButton {
    padding: 5px 10px;
    background-color: #ccc;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.buttonDiv {
    display: flex;
    justify-content: center;
}

.availabilityMessage {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: IBMPlexMono;
}

.availabilityMessageAvailable {
    color: green;
}

.availabilityMessageNotAvailable {
    color: red;
}

/* Responsive styles */
@media (max-width: 768px) {
    .columns {
        grid-template-columns: 1fr; /* Stack columns vertically on small screens */
    }

    .leftColumn, .rightColumn {
        padding: 10px; /* Reduced padding on smaller screens */
    }

    .imageContainer {
        height: 250px; /* Smaller height for image container on mobile */
    }

    .header {
        font-size: 1.5rem; /* Smaller header font size */
    }

    .subHeader {
        font-size: 1.2rem; /* Smaller subheader font size */
    }

    .priceTitle {
        font-size: large; /* Adjust price title size */
    }

    .availability {
        font-size: small; /* Adjust font size for availability */
    }

    .bookingSectionTitle {
        font-size: medium; /* Adjust booking section title size */
    }
}
