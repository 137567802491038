.bgGray100 {
    background-color: #f4f5f7;
}

.container {
    margin-left: 1em;
    margin-right: 1em;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    border: 0.5px solid rgba(181, 181, 181, 0.734);
}

.itemsList {
    margin-top: 20px;
    overflow-y: auto;
    flex-grow: 1;
}

.gridCard {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
    margin-bottom: 1em;
    border-bottom: 0.5px solid rgba(181, 181, 181, 0.734);
    padding-bottom: 1em;
}

.worxInfo {
    display: grid;
    padding-left: 1em;
}

.titleWorx {
    font-family: IBMPlexMono;
    font-size: 20px;
    color: #141414;
    cursor: pointer;
}

.address {
    font-family: IBMPlexMonoExtraLight;
    font-size: 14px;
}

.aminities {
    margin-bottom: 0px;
    margin-top: 0px;
    font-family: IBMPlexMonoExtraLight;
    font-size: 14px;
}

.gridPriceAndRating {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em;
    align-items: end;
}

.rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: IBMPlexMonoExtraLight;
    font-size: 14px;
    gap: 5px;
}

.price {
    justify-self: end;
    font-family: IBMPlexMonoExtraLight;
    font-size: 14px;
}

.imageContainer {
    position: relative;
    width: 100%;
    height: 200px;
    padding-right: 1em;
}

.imgWorx {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.clearIconContainer {
    position: absolute;
    top: 1em;
    right: 2em;
    width: 24px;
    height: 24px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.clearIcon {
    width: 16px;
    height: 16px;
    fill: white;
}
