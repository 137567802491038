.hoverEffectText{
    font-family: IBMPlexMono;
    font-size: 10px;
    color: #ffffff;
    margin: 0;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-transform: uppercase;
    cursor: pointer;
    width: max-content;
    display: block;
    padding: 0px 2px;
  }

  .hoverEffectText:hover {
    background-color: #fff;
    color: #000000;
  }