@font-face {
    font-family: IBMPlexMono;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
}

@font-face {
    font-family: IBMPlexMonoLight;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
}

@font-face {
    font-family: IBMPlexMonoExtraLight;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
}

.bgGray100 {
    background-color: #f4f5f7;
    min-height: 100vh;
}

.container {
    margin: 0 1em 1em 1em;
    border-left: 0.5px solid rgba(181, 181, 181, 0.734);
    border-right: 0.5px solid rgba(181, 181, 181, 0.734);
    border-bottom: 0.5px solid rgba(181, 181, 181, 0.734);

}

.userHeader {
    text-align: center;
    padding: 20px;
}

.profilePhoto {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.userDetails {
    display: flex;
    flex-direction: column;
    align-items: center;
}
    
.name {
    font-family: IBMPlexMono;
}

.upgradeButton {
    color: #888;
    font-size: 14px;
    cursor: pointer;
}

.bookingsSavedSection {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    justify-content: space-around;
    padding: 20px;
}

.bookings {
    justify-self: end;
}

.bookingsSection,
.savedSection {
    display: grid;
    grid-template-rows: auto 1fr;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
}

.savedSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.goToButton {
    color: #888;
    font-size: 14px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.sectionInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.bookingsCount,
.savedCount {
    font-size: 30px;
    color: #369FFF;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.photoGrid {
    display: flex;
    gap: 5px;
    overflow-x: auto;
    align-items: center;
}

.photoThumbnail {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    object-fit: cover;
}

.userOptions ul {
    list-style-type: none;
    padding: 0;
}

.userOptions li {
    display: flex;
    align-items: center;
    padding: 0.3rem 0;
    font-size: 1rem;
    cursor: pointer;
}

.userOptions li svg {
    margin-right: 0.5rem;
    margin-left: 2em;
}

.logout {
    color: red;
    margin-left: 2em;
    font-family: IBMPlexMonoLight;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 400px;
    width: 80%;
}

.modalActions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.cancelButton,
.logoutButton {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.cancelButton {
    background-color: #ccc;
    color: #fff;
}

.logoutButton {
    background-color: #d9534f;
    color: #fff;
}

.editPhotoIcon {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.tos{
    font-family: IBMPlexMonoLight;
    text-decoration: underline;
}
