@font-face {
  font-family: IBMPlexMono;
  src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
}

@font-face {
  font-family: IBMPlexMonoLight;
  src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
}

@font-face {
  font-family: IBMPlexMonoExtraLight;
  src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
}

.bgGray100 {
  background-color: #f4f5f7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f4e6db;
  border-bottom: 0.5px solid rgba(181, 181, 181, 0.734);
  padding: 1rem;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: 7rem 
}

.worxContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-left: 0.5px solid rgba(181, 181, 181, 0.734);
  border-right: 0.5px solid rgba(181, 181, 181, 0.734);
  border-bottom: 0.5px solid rgba(181, 181, 181, 0.734);
  margin-left: 1rem;
  margin-right: 1rem;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
}

.hotelTitle {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  font-family: IBMPlexMono;
  margin-bottom: 1rem;
}

.hotelTagline {
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  font-family: IBMPlexMono;
  margin-bottom: 2rem;
  padding-inline: 5rem;
}

.hotelImageContainer {
  position: relative;
}

.hotelImage {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  object-fit: cover;
  max-height: 300px;
}   

.hotelLogoOverlay {
  position: absolute;
  top: 35px; /* Adjust as needed to position the logo */
  left: 50px; /* Adjust as needed */
  width: 200px; /* Adjust logo size as needed */
  height: auto;
  z-index: 10; /* Ensures logo appears above the image */
}

.hotelNameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin-top: 20px; /* Adjust as needed */
  margin-bottom: 3rem;
  margin-inline: auto;
}

.hotelNamePhoto {
  max-width: 100%;
  height: auto;
}

.hotelTitle {
  text-align: center;
  font-size: 3rem;
}

.hotelAddress,
.hotelCity,
.hotelPhone,
.hotelEmail {
  font-size: 1rem;
  margin: 0.3rem 1rem;
  font-family: IBMPlexMonoExtraLight;
}

.spacesTitle {
  font-size: 20px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  text-align: center;
  font-family: IBMPlexMono;
}

.spacesList {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
}

.spaceItem {
  border: 1px solid #ddd;
  padding: 10px;
  width: calc(33.333% - 20px);
  height: 300px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr auto auto;
}

@keyframes pan-image {
  0% {
    background-position: 36% 42%;
    background-size: 200%;
  }
  20% {
    background-position: 30% 35%;
    background-size: 200%;    
  }
  40% {
    background-position: 24% 28%;
    background-size: 200%;
  }
  60% {
    background-position: 18% 21%;
    background-size: 200%;
  }
  80% {
    background-position: 12% 14%;
    background-size: 200%;
  }
  100% {
    background-position: 6% 7%;
    background-size: 200%;
  }
  120% {
    background-position: 12% 14%;
    background-size: 200%;
  }
  140% {
    background-position: 18% 21%;
    background-size: 200%;
  }
  160% {
    background-position: 24% 28%;
    background-size: 200%;
  }
  180% {
    background-position: 30% 35%;
    background-size: 200%;
  }
  200% {
    background-position: 36% 42%;
    background-size: 200%;
  }
}

.spaceImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 300%;
  background-position: center;
  z-index: 1;
  animation: pan-image 30s linear infinite alternate;
}

.spaceTitle {
  font-size: 18px;
  font-family: IBMPlexMono;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.734);
  display: inline-block;
  height: max-content;
  width: max-content;
  text-align: center;
  margin: 0px auto;
  padding: 0px 0.2rem;
}

.infoGrid {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  z-index: 2;
}

.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexColumn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.flexP {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  background-color: rgba(255, 255, 255, 0.926);
  padding: 0.2rem 0.5rem;
  color: black;
  margin-bottom: 0.5rem;
}

.flexP p {
  margin: 0;
}

.toggleContainer {
display: flex;
justify-content: center;
margin-top: 1rem;
gap: 2rem;
}

.inputContainer {
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0;
  z-index: 9999;
  font-family: IBMPlexMonoLight;
}

.personsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.datePicker {
  background: none;
  border: none;
  border-bottom: 1.5px solid #d7dedd;
  color: #1f1f1fcc;
  font-family: IBMPlexMonoLight;
  margin: 5px 0;
  outline: none;
  padding: 20px 10px 10px 20px;
  width: 40%;
  z-index: 9999;
  transition: width 0.3s ease;
}

.datePicker.wide {
  width: 60%;
}

.datePickerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
  transition: width 0.3s ease;
}

.datePickerContainer.wide {
  width: 100%;
}

.personsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 50%;
}

.inputContainer.wide {
  justify-content: center;
}

.personsSelector {
  display: flex;
  align-items: center;
}

.decrementButton, .incrementButton {
  padding: 0.3rem 3rem;
  font-size: 16px;
  cursor: pointer;
  border-radius: 20px;
}

@media (max-width: 768px) {
  .container {
    padding: 0.5rem;
  }

  .worxContainer {
    margin-left: 0;
    margin-right: 0;
    border-left: none;
    border-right: none;
    padding: 1rem;
    padding-bottom: 3rem 
  }

  .contentWrapper {
    margin: 0.5rem 0;
  }

  .hotelTitle {
    font-size: 20px;
  }

  .hotelAddress,
  .hotelCity,
  .hotelPhone,
  .hotelEmail {
    font-size: 14px;
    margin: 0.2rem 0.5rem;
  }

  .spacesTitle {
    font-size: 18px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .spaceItem {
    width: calc(100% - 20px);
    max-width: 100%;
  }

  .inputContainer {
    flex-direction: column;
    align-items: center;
  }

  .datePicker {
    width: 80%;
  }

  .toggleContainer {
    flex-direction: column;
    gap: 1rem;
  }

  .spaceTitle {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .hotelTitle {
    font-size: 18px;
  }

  .hotelAddress,
  .hotelCity,
  .hotelPhone,
  .hotelEmail {
    font-size: 12px;
  }

  .spacesTitle {
    font-size: 16px;
  }

  .spaceItem {
    height: auto;
  }

  .spaceTitle {
    font-size: 14px;
  }
}