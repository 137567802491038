@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Scroll bar stylings */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--lightestgrey); 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #adadad; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888; 
  }

/* Hide scrollbar on mobile devices */
@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}
