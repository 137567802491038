@font-face {
    font-family: IBMPlexMono;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
  }
  
  @font-face {
    font-family: IBMPlexMonoLight;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
  }
  
  @font-face {
    font-family: IBMPlexMonoExtraLight;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
  }
  
  :root {
    --border: rgb(3, 169, 244);
    --g1: #FF4900;
    --g2: rgb(236, 64, 122);
    --g3: rgb(253, 216, 53);
  }
  
  .card {
    font-family: IBMPlexMono, sans-serif;
    aspect-ratio: 1 / 0.5; 
    border: 0.5vmin solid var(--border);
    cursor: pointer;
    position: relative;
    width: 30vmin;
    margin-bottom: 10px;
  }
  
  .card:hover:before {
    background-position: 100% 100%;
    transform: scale(1.08, 1.03);
  }
  
  .card:hover > .cardContent {
    background-position: -10% 0%;
  }
  
  .card:hover > .cardIcon {
    color: white;
  }
  
  .card:before {
    background: linear-gradient(
      130deg,
      transparent 0% 33%,
      var(--g1) 66%,
      var(--g2) 83.5%,
      var(--g3) 100%
    );
    background-position: 0% 0%;
    background-size: 300% 300%;
    content: "";
    height: 100%;
    left: 0px;
    pointer-events: none;
    position: absolute;
    top: 0px;
    transition: background-position 350ms ease, transform 350ms ease;
    width: 100%;
    z-index: 1;
  }
  
  .cardContent {
    background-image: radial-gradient(
      rgba(255, 255, 255, 0.2) 8%,
      transparent 8%
    );
    background-position: 0% 0%;
    background-size: 5vmin 5vmin;
    height: calc(100% - 2vmin);
    padding: 1vmin; 
    position: relative;
    transition: background-position 350ms ease;
    width: calc(100% - 2vmin);
    z-index: 2;
  }
  
  .cardTitle,
  .cardSubtitle {
    color: white;
    font-family: IBMPlexMono, sans-serif;
    font-weight: 400;
    margin: 0px;
  }
  
  .cardTitle {
    font-size: 2.5vmin; 
  }
  
  .cardSubtitle {
    font-size: 1.5vmin; 
    margin-top: 0.5vmin; 
  }
  
  .cardSubtitle span {
    display: block; 
    margin-bottom: 0.5vmin; 
  }
  
  .cardIcon {
    bottom: 0px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 3vmin; 
    left: 0px;
    margin: 1vmin; 
    position: absolute;
    transition: color 250ms ease;
    z-index: 2;
  }
  