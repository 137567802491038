@font-face {
    font-family: IBMPlexMono;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
}

@font-face {
    font-family: IBMPlexMonoLight;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
}

@font-face {
    font-family: IBMPlexMonoExtraLight;
    src: url("../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
}

.container {
  background-color: #f4f5f7;
  min-height: 100vh;
}

.containerInside {
    margin-left: 1em;
    margin-right: 1em;

    display: flex;
    flex-direction: column;
    border-left: 0.5px solid rgba(181, 181, 181, 0.734);
    border-right: 0.5px solid rgba(181, 181, 181, 0.734);
    border-bottom:  0.5px solid rgba(181, 181, 181, 0.734);
}

.main {
  padding-top: 3rem;
}

.section {
  padding: 4rem 1rem;
}

@media (min-width: 768px) {
  .section {
    padding: 4rem 2rem;
  }
}

.heroSection {
  background-color: #FF4900;
  color: white;
  text-align: center;
  font-family: IBMPlexMono;
  font-size: 0.7rem;
}

.heroTitle {
  font-size: 2.25rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .heroTitle {
    font-size: 2rem;
    padding-inline: 9rem;
    margin-bottom: 25px;
  }
}

.logo_cursor {
  cursor: pointer;
  width: 18%;
  height: auto;
  padding-left: 2rem;
  padding-top: 1rem;
}

.heroSubtitle {
  font-size: 1.25rem;
  margin-bottom: 2rem;
  line-height: 2rem;
  font-family: IBMPlexMono;
}

@media (min-width: 768px) {
  .heroSubtitle {
    font-size: 1.5rem;
  }
}

.button {
  background-color: white;
  color: rgb(29, 29, 29);
  font-weight: bold;
  padding: 0.75rem 1.5rem;
  border-radius: 9999px;
  font-size: 1.125rem;
  transition: background-color 0.3s;
  text-decoration: underline;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.sectionTitle {
  font-size: 1.875rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
  font-family: IBMPlexMono;
}

@media (min-width: 768px) {
  .sectionTitle {
    font-size: 2.25rem;
  }
}

.workspaceGrid {
  display: grid;
  gap: 2rem;
}

@media (min-width: 768px) {
  .workspaceGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .workspaceGrid {
    grid-template-columns: repeat(4, 1fr);
  }
}

.workspaceCard {
  background-color: var(--color-white);
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.workspaceIcon {
  color: var(--color-primary);
  margin-bottom: 1rem;
}

.imageSection {
  padding: 5rem 1rem;
  background-color: #f4f5f7;
}

.imageSlider {
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
}

.sliderImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.sliderImage.active {
  opacity: 1;
}

.workspaceTitle {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-family: IBMPlexMono;
}

.workspaceDescription {
  color: var(--color-gray-600);
  font-family: IBMPlexMonoLight;
}

.graySection {
  background-color: var(--color-gray-100);
}

.contentWrapper {
  max-width: 64rem;
  margin: 0 auto;
  text-align: center;
}

.featureList {
  text-align: left;
  display: inline-block;
}

.featureItem {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-family: IBMPlexMonoLight;
}

.featureIcon {
  color: #FF4900;
  margin-right: 0.5rem;
}

.offerCard {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.offerTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: IBMPlexMono;
}

.offerDescription {
  color: var(--color-gray-600);
  margin-bottom: 1.5rem;
  font-family: IBMPlexMonoLight;
}

.offerList {
  list-style-type: none;
  padding: 0;
}

.offerItem {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-family: IBMPlexMonoLight;
}

.footer {
  background-color: var(--color-gray-800);
  color: var(--color-white);
  padding: 2rem 1rem;
}

.footerContent {
  max-width: 72rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .footerContent {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footerLogo {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .footerLogo {
    margin-bottom: 0;
  }
}

.footerLinks {
  display: flex;
  gap: 1rem;
}

.footerLink {
  color: var(--color-white);
}

.footerLink:hover {
  color: var(--color-primary);
}