@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

* {
    box-sizing: border-box;
}

h1 {
    font-weight: bold;
    margin: 0;
}

h2 {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
}

p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
}

span {
    font-size: 12px;
}

a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

.button-form {
    border-radius: 20px;
    border: 1px solid #FF4B2B;
    background-color: #FF4B2B;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 0.6s ease-in-out;
    cursor: pointer;
    margin-top: 1em;
}

.button-form:active {
    transform: scale(0.95);
}

.button-form:focus {
    outline: none;
}

.button-form:hover {
    transform: scale(0.95);
}


button.ghost {
    background-color: transparent;
    border-color: #FFFFFF;
}

form {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    text-align: center;
    padding: 0px 0px !important;
}

.input-field {
    background-color: #eee;
    border: none !important;
    margin: 8px 0;
    width: 80% !important;
    font-size: 16px;
    border-radius: 0px !important;
}

.input-field::placeholder {
    color: #ccc;
}

.register-button {
    width: 50%;
    padding: 10px;
    background-color: #0000ff;
    color: #fff;
    border: none;
    border-radius: 8px;
    align-self: flex-end;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.register-button:hover {
    background-color: #002398;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
    display: block;
    font-weight: bold;
}

.register-container {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0px 0px !important;
    height: min-content;
    width: 100%;
    text-align: center;
}

