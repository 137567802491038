/* src/components/NotFound.css */

.not-found-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
    color: black;
    text-align: center;
}

.not-found-content {
    max-width: 600px;
}

.not-found-content h1 {
    font-size: 10rem;
    margin: 0;
}

.not-found-content h2 {
    font-size: 2rem;
    margin: 20px 0;
}

.not-found-content p {
    font-size: 1.2rem;
    margin: 20px 0;
}

.home-link {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 1rem;
    color: white;
    background-color: black;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.home-link:hover {
    background-color: #333;
}