@font-face {
  font-family: ABCWhyte;
  src: url("../fonts/ABC_Whyte/ABCWhyte-Super-Trial.woff");
}

.hoverEffectButtons {
  font-family: ABCWhyte;
  text-transform: uppercase;
  font-size: 34px;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
  padding: 0px 5px 0px 5px; 
  line-height: 1;
  display: inline-block;
  width: fit-content; 
  margin-bottom: 0.2em;
  word-break: break-word; 
}

.hoverEffectButtons:hover {
  background-color: #fff;
  color: #000000;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
}

.hoverEffectButtons.selected {
  background-color: #ff4900;
  color: #000000;
  clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
}
