@font-face {
  font-family: IBMPlexMono;
  src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
}

@font-face {
  font-family: IBMPlexMonoLight;
  src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
}

@font-face {
  font-family: IBMPlexMonoExtraLight;
  src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
}

.footer {
  font-family: IBMPlexMono, sans-serif;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 1.5rem;
  background-color: #0e0e0e;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  font-size: 0.875rem;
  z-index: 1000;
  transition: height 0.3s ease-in-out;
  overflow: hidden;
}

.footer:hover {
  height: fit-content;
}

.footerContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  transition: opacity 0.3s ease-in-out;
}

.footer:hover .footerContent {
  opacity: 1;
}

.hiddenInfo {
  opacity: 0;
  display: none;
  width: 100%;
}

.footer:hover .hiddenInfo {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.cardsContainer {
  display: none;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
  width: 100%;
  justify-content: center;
}

.footer:hover .cardsContainer {
  display: flex;
}

.title {
  font-family: IBMPlexMono, sans-serif;
}

.letter {
  font-family: IBMPlexMonoLight, sans-serif;
}


@media (max-width: 768px) {
  .footer {
    font-size: 0.75rem;
    padding: 0 0.5rem;
  }

  .footerContent {
    flex-direction: column;
    align-items: center;
  }

  .cardsContainer {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media (max-width: 570px) {
  .footer {
    font-size: 0.6rem;
    padding: 0 0.25rem;
  }

  .footerContent {
    text-align: center;
    align-items: center;
  }

  .cardsContainer {
    flex-direction: column;
    gap: 0.5rem;
  }

  .cardsContainer > div {
    width: 100%; 
  }
}
