@font-face {
    font-family: IBMPlexMono;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Medium.ttf");
}

@font-face {
    font-family: IBMPlexMonoLight;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-Light.ttf");
}

@font-face {
    font-family: IBMPlexMonoExtraLight;
    src: url("../../fonts/IBM_Plex_Mono/IBMPlexMono-ExtraLight.ttf");
}

.bgGray100 {
    background-color: #f4f5f7;
    min-height: 100vh;
}

.pageTitle {
    color: #000;
    text-align: left;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 1.5rem;
}

.buttonsDiv {
    display:flex;
    justify-content: center;
    margin-top: 1.5rem;
    gap: 1.5rem;
}

.optionsStyle {
    width: 100%;
    max-width: 315px;
    height: 59px;
    flex-shrink: 0;
    border-radius: 33px;
    background: #EBEFF1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 2rem;
}

.selectedButton {
    width: 45%;
    height: 45px;
    flex-shrink: 0;
    border-radius: 33px;
    background: rgba(255, 255, 255, 0.80);
    color: #141414;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 25px;
    text-align: center;
    box-shadow: 0px 20px 40px 0px rgba(55, 62, 125, 0.10);
}

.unselectedButton {
    width: 45%;
    height: 40px;
    flex-shrink: 0;
    border-radius: 33px;
    background: transparent;
    color: #000;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    text-align: center;
}

.bookings {
    display: flex;
    flex-direction: column;
    margin: 1em;
}

.bookingsList {
    display: flex;
    flex-direction: column;
}

.groupedBookings {
    border-bottom: 1px solid #ccc;
    margin-bottom: 15px;
}

.bookingCardDate {
    font-family: IBMPlexMono;
}

.totalAmount {
    font-size: 1em;
    font-weight: normal;
    margin-left: 10px;
    font-family: IBMPlexMono;
}

.mainContent {
    height: calc(100vh - 64px); /* Adjust based on header height */
    overflow-y: auto;
    margin: 0em 1em;
    border-left: 0.5px solid rgba(181, 181, 181, 0.734);
    border-right: 0.5px solid rgba(181, 181, 181, 0.734);
    border-bottom: 0.5px solid rgba(181, 181, 181, 0.734);
}

.bookingsList {
    padding-bottom: 30px; /* Extra space for the bottom navbar */
}
